<template>
  <div>
    <div class="img_container" @click="dialogClick">
      <i class="el-icon-plus" v-if="files.length === 0"></i>
      <el-image :src="files[0].url" style="height: 100%;width: 100%;" v-else></el-image>
    </div>
    <el-dialog
      title="地图"
      :visible.sync="showDialog"
      :modal="false"
      :close-on-click-modal="false"
      :center="true"
      width="50%"
      :before-close="dialogClick">
      <div id="container">
        <el-form size="medium" :inline="true" @submit.native.prevent style="width: 100%;">
          <el-form-item label="城市">
            <el-input
              v-model="input"
              @change="search"
              @submit.native.prevent
              placeholder="请输入城市地面"/>
          </el-form-item>
        </el-form>
        <div ref="Map" id="Map"></div>
        <el-button id="McButton" @click="submit" type="primary">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

// 引入AMapLoader
import AMapLoader from '@amap/amap-jsapi-loader'
import { screenshot } from '@/utils/File'
import { UploadFile } from '@/request/Common'
import { errorTip } from '@/utils/Tip'

window._AMapSecurityConfig = {
  // 设置安全密钥
  securityJsCode: 'fb8f2f17f446ba379c57cb65a0f6feca'
}
export default {
  name: 'McLocation',
  props: {
    city: {
      // 数据类型
      type: Array,
      // 约束是否必须传值
      required: true
    },
    field: {
      // 数据类型
      type: String,
      // 约束是否必须传值
      required: true
    }
  },
  data () {
    return {
      showDialog: false,
      // 弹窗
      loading: false,
      // 地图对象
      map: null,
      placeSearch: null,
      input: '',
      files: [
        {
          name: '',
          path: '',
          url: ''
        }
      ]
    }
  },
  created () {
    this.files = this.city
  },
  methods: {
    // 地图初始化
    initMap () {
      AMapLoader.load({
        // 申请好的Web端开发者Key，首次调用 load 时必填
        key: '3d1fc4891efc2dd8eb78295d9c4f301b',
        // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        version: '2.0',
        // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        plugins: ['AMap.AutoComplete', 'AMap.Geocoder', 'AMap.PlaceSearch']
      }).then((AMap) => {
        // 设置地图容器id
        this.map = new AMap.Map('Map', {
          pitch: 0, // 地图俯仰角度，有效范围 0 度- 83 度
          viewMode: '3D', // 地图模式
          zoom: 11, // 初始化地图层级
          // 加上这串代码
          WebGLParams: {
            preserveDrawingBuffer: true
          }
        })
        this.placeSearch = new AMap.PlaceSearch({
          pageSize: 1,
          pageIndex: 1
          // map: this.map
        })
      }).catch(e => {
      })
    },
    // 转换成图片
    submit: async function () {
      const canvas = document.getElementById('Map')
      // screenshot
      // 截图
      const formData = await screenshot(canvas)
      // 上传截图
      const result = await UploadFile(formData)
      if (result.code !== 200) {
        errorTip(this, result.message)
      }
      this.files[0] = result.data
      this.$emit('event', this.field, [result.data])
      this.showDialog = false
    },
    dialogClick: function () {
      this.map = null
      if (!this.showDialog) {
        this.initMap()
      }
      // 重置form数据
      this.showDialog = !this.showDialog
    },
    // 关键字查询
    search: function (val) {
      const _this = this
      // 清除上次搜索结果
      // 搜索
      this.placeSearch.search(val, function (status, result) {
        const lat = result.poiList.pois[0].location.lat
        const lng = result.poiList.pois[0].location.lng
        _this.map.setCenter([lng, lat])
        _this.map.setZoom(11)
      })
      this.placeSearch.clear()
    },
    handleEnter: function (event) {
      // 在这里你可以处理 Enter 键的逻辑
      console.log('Enter key pressed:', event)
      // 可以在这里调用其他方法或者做一些其他的事情
    }
  }
}
</script>

<style scoped lang="less">
#container {
  padding: 0;
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
}

#Map {
  height: 90%;
  width: 100%;
}

#McButton {
  width: 10%;
}

.img_container {
  width: 30vw;
  height: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.07);
}

.search {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
