// 统一接口地址
export const baseUrl = process.env.NODE_ENV === 'development'
  ? 'https://7fypxkh4n0.hszdjz.cn/'
  : 'https://7fypxkh4n0.hszdjz.cn/'

// 文件存储地址
export const cdnUrl = 'https://mcrrf3u4tu.hszdjz.cn/'
// 文件类型
export const fileScene = {
  office: '.pdf, .doc, .docx, .xls, .xlsx',
  image: '.png, .jpg, .gif, .psd, .svg',
  video: '.mp4, .rmvd, .mkv, .avi, .mp3'
}
