import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // 主路由
  {
    path: '/',
    name: 'Main',
    redirect: '/SystemHome',
    component: () => import('../views/System/Main.vue'),
    children: [
      {
        path: 'BusinessDate',
        name: 'BusinessDate',
        component: () => import('../views/Business/Date.vue')
      },
      {
        path: 'BusinessSchool',
        name: 'BusinessSchool',
        component: () => import('../views/Business/School.vue')
      },
      {
        path: 'BusinessCertificate',
        name: 'BusinessCertificate',
        component: () => import('../views/Business/Certificate.vue')
      },
      {
        path: 'BusinessContribute',
        name: 'BusinessContribute',
        component: () => import('../views/Business/Contribute.vue')
      },
      {
        path: 'BusinessImport',
        name: 'BusinessImport',
        component: () => import('../views/Business/Import.vue')
      },
      {
        path: 'BusinessEnroll',
        name: 'BusinessEnroll',
        component: () => import('../views/Business/Enroll.vue')
      },
      {
        path: 'BusinessWebsite',
        name: 'BusinessWebsite',
        component: () => import('../views/Business/Website.vue')
      },
      {
        path: 'BusinessContact',
        name: 'BusinessContact',
        component: () => import('../views/Business/Contact.vue')
      },
      {
        path: 'BusinessTemplate',
        name: 'BusinessTemplate',
        component: () => import('../views/Business/Template.vue')
      },
      {
        path: 'BusinessBanner',
        name: 'BusinessBanner',
        component: () => import('../views/Business/Banner.vue')
      },
      {
        path: 'SystemRole',
        name: 'SystemRole',
        component: () => import('../views/System/Role.vue')
      },
      {
        path: 'SystemLog',
        name: 'SystemLog',
        component: () => import('../views/System/Log.vue')
      },
      {
        path: 'SystemUser',
        name: 'SystemUser',
        component: () => import('../views/System/User.vue')
      },
      {
        path: 'SystemSite',
        name: 'SystemSite',
        component: () => import('../views/System/Site.vue')
      },
      {
        path: 'SystemHome',
        name: 'SystemHome',
        component: () => import('../views/System/Home.vue')
      },
      {
        path: 'SystemMenu',
        name: 'SystemMenu',
        component: () => import('../views/System/Menu.vue')
      },
      {
        path: 'SystemCreate',
        name: 'SystemCreate',
        component: () => import('../views/System/Create.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/System/Login.vue')
  }
]

const router = new VueRouter({
  base: '/',
  mode: 'hash', // 去掉url中的#
  routes
})

// router.addRoute()

export default router
